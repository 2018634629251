import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, NavLink } from "react-router-dom";
import { Layout, Menu, Typography, Badge } from "antd";
import { InboxOutlined, UserOutlined, RobotOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { SET_APP } from "@/actions/app";
import { call } from "@/actions/axios";
import "./index.less";

const { Sider } = Layout;
const { Text } = Typography;

const Comp = () => {
  // const { logout } = useAuth0();
  const { pathname } = useLocation();

  let selectedKeys = [pathname];

  useEffect(() => {
    const getUser = async () => {
      const { data } = await dispatch(call({ url: `/users/check` }));
      await dispatch(SET_APP(["user"], data));
    };
    // getUser();
  }, []);

  const dispatch = useDispatch();

  return (
    <Sider
      width={240}
      theme="light"
      trigger={null}
      collapsed={false}
      collapsible
    >
      <div className="logo" />
      <Menu selectedKeys={selectedKeys}>
        <Menu.Item key="/">
          <NavLink to="/">
            <Badge dot>
              <InboxOutlined />
            </Badge>
            <span className="title">Диалоги</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/clients">
          <NavLink to="/clients">
            <UserOutlined />
            <span className="title">Контакты</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/bot">
          <NavLink to="/bot">
            <RobotOutlined />
            <span className="title">Чат-бот</span>
          </NavLink>
        </Menu.Item>
      </Menu>
      <div className="margin-menu">
        <Text className="margin-title" type="secondary">
          Модули
        </Text>
        <Menu selectedKeys={selectedKeys}>
          <Menu.Item key="/analytics">
            <NavLink to="/analytics">Аналитика</NavLink>
          </Menu.Item>
          <Menu.Item key="/library">
            <NavLink to="/library">Библиотека</NavLink>
          </Menu.Item>
        </Menu>
      </div>
      <div className="margin-menu">
        <Text className="margin-title" type="secondary">
          Управление
        </Text>
        <Menu selectedKeys={selectedKeys}>
          <Menu.Item key="/settings">
            <NavLink to="/settings">Настройки</NavLink>
          </Menu.Item>
        </Menu>
      </div>
    </Sider>
  );
};

export default Comp;
