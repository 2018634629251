import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { Routes, Route } from "react-router-dom";
import { Layout } from "antd";
import { SET_APP } from "@/actions/app";

import { Sider, Loading } from "./ui";

import "./App.less";

const Home = React.lazy(() => import("./screens/Home"));
const Clients = React.lazy(() => import("./screens/Clients"));
const NotFound = React.lazy(() => import("./screens/NotFound"));

export const AppContext = React.createContext();

const App = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) loginWithRedirect();
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  useEffect(() => {
    const init = async () => {
      const token = await getAccessTokenSilently();
      dispatch(SET_APP(["token"], token));
    };
    init();
  }, []);

  if (isLoading || !isAuthenticated) return <Loading />;

  return (
    <React.Suspense fallback={<Loading />}>
      <AppContext.Provider value={{ mode: "desktop" }}>
        <Layout className="main__wrapper">
          <Sider />
          <Layout>
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/clients" element={<Clients />} />
              <Route path="/bot" element={<NotFound />} />
              <Route path="/analytics" element={<NotFound />} />
              <Route path="/library" element={<NotFound />} />
              <Route path="/settings" element={<NotFound />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Layout>
        </Layout>
      </AppContext.Provider>
    </React.Suspense>
  );
};
export default App;
